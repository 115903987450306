import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onMounted } from 'vue';
import { IconName } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';
import { VIcon } from '@components/v-icon';
import useReferralProgramInfoPage from 'web/src/modules/referral-program/pages/ReferralProgramInfoPage/composables/useReferralProgramInfoPage';
import { VLoaderDelayed } from 'web/src/components/Loader';
import ReferralProgramRewardItem from 'web/src/modules/referral-program/pages/ReferralProgramInfoPage/components/ReferralProgramRewardItem/ReferralProgramRewardItem.vue';
import ReferralProgramInfoBonus from 'web/src/modules/referral-program/pages/ReferralProgramInfoPage/components/ReferralProgramInfoBonus/ReferralProgramInfoBonus.vue';
import SNumberedList from 'web/src/components/NumberedList/SNumberedList/SNumberedList.vue';
import SNumberedListItem from 'web/src/components/NumberedListItem/SNumberedListItem/SNumberedListItem.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramInfoPage',
    setup (__props) {
        const { itemList, isLoading, nominals, runOnMounted, rewardMatrix, onClickTerms } = useReferralProgramInfoPage();
        onMounted(runOnMounted);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _unref(isLoading) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                key: 0
            })) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['referral-program-info-page'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(itemList), (item, index)=>_withDirectives((_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass(_ctx.$style['referral-program-info-page__item'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['referral-program-info-page__item-content'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['referral-program-info-page__icon'])
                                }, [
                                    _createVNode(_unref(VIcon), {
                                        class: _normalizeClass(_ctx.$style['referral-program-info-page__icon-svg']),
                                        name: item.icon
                                    }, null, 8, [
                                        "class",
                                        "name"
                                    ])
                                ], 2),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['referral-program-info-page__item-label'])
                                }, _toDisplayString(item.label), 3)
                            ], 2),
                            item.table ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['referral-program-info-page__table'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['referral-program-info-page__table-row']]: true,
                                        [_ctx.$style['referral-program-info-page__table-row--header']]: true
                                    })
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.table.header, (title)=>(_openBlock(), _createElementBlock("span", {
                                            key: title,
                                            class: _normalizeClass(_ctx.$style['referral-program-info-page__table-cell'])
                                        }, _toDisplayString(title), 3))), 128))
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['referral-program-info-page__table-rows'])
                                }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.table.body, (cell, cellIndex)=>(_openBlock(), _createElementBlock("div", {
                                            key: 'cell_' + cellIndex,
                                            class: _normalizeClass(_ctx.$style['referral-program-info-page__table-row'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['referral-program-info-page__table-cell'])
                                            }, _toDisplayString(cell.col1), 3),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['referral-program-info-page__table-cell'])
                                            }, [
                                                _createVNode(ReferralProgramRewardItem, {
                                                    items: cell.col2
                                                }, null, 8, [
                                                    "items"
                                                ])
                                            ], 2),
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['referral-program-info-page__table-cell'])
                                            }, [
                                                _createVNode(ReferralProgramRewardItem, {
                                                    items: cell.col3
                                                }, null, 8, [
                                                    "items"
                                                ])
                                            ], 2)
                                        ], 2))), 128))
                                ], 2)
                            ], 2)) : _createCommentVNode("", true)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'referral-program-info-page-item'
                                }
                            ]
                        ])), 128)),
                    _withDirectives((_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-info-page__label'])
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('REFERRAL_PROGRAM_INFO_LABEL')) + " ", 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(nominals), (item, index)=>(_openBlock(), _createElementBlock("span", {
                                key: index
                            }, [
                                _createTextVNode(_toDisplayString(item.title) + " - " + _toDisplayString(item.value), 1),
                                index < _unref(nominals).length - 1 ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _createTextVNode(", ")
                                ], 64)) : _createCommentVNode("", true)
                            ]))), 128))
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'referral-program-info-page-label'
                            }
                        ]
                    ]),
                    _createCommentVNode("", true)
                ], 64))
            ], 2));
        };
    }
});
