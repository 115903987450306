import getCompletedProgramRequirements from 'web/src/modules/referral-program/utils/referralProgramPopup/getCompletedProgramRequirements';
export default function getHeaderProps(isOpen, programReferral, isLastStep, description) {
    const completedProgramRequirements = getCompletedProgramRequirements(programReferral);
    if (isLastStep) return {
        isOpen: false,
        successMessage: description
    };
    return {
        isOpen,
        activeStep: completedProgramRequirements.length,
        stepCount: programReferral?.referralDetails?.requirements.length || 0
    };
}
