import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, unref as _unref, mergeProps as _mergeProps, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
const _hoisted_2 = [
    "src"
];
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useReferralProgramPopupHeader } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramPopupHeader',
    props: {
        isOpen: {
            type: Boolean
        },
        successMessage: {},
        activeStep: {},
        stepCount: {}
    },
    emits: [
        "click",
        "close",
        "redirect"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { iconProps, buttonProps, handleClickOnContainer, handleRedirectToBonuses, handleClose } = useReferralProgramPopupHeader(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['referral-program-popup-header']]: true,
                    [_ctx.$style['referral-program-popup-header_pointer']]: !_ctx.successMessage
                }),
                onClick: _cache[1] || (_cache[1] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(handleClickOnContainer) && _unref(handleClickOnContainer)(...args);
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-popup-header__left'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-popup-header__left-content'])
                    }, [
                        _createElementVNode("p", {
                            class: _normalizeClass(_ctx.$style['referral-program-popup-header__title'])
                        }, _toDisplayString(_ctx.successMessage ? _ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_BONUS_TITLE') : _ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_TITLE')), 3),
                        _createElementVNode("p", {
                            class: _normalizeClass(_ctx.$style['referral-program-popup-header__steps'])
                        }, [
                            _ctx.successMessage ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.successMessage), 1)) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 1
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_STEPS')) + " ", 1),
                                _createElementVNode("span", null, _toDisplayString(_ctx.activeStep), 1),
                                _createTextVNode("/" + _toDisplayString(_ctx.stepCount), 1)
                            ], 64))
                        ], 2)
                    ], 2),
                    _ctx.successMessage ? (_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 0
                    }, _unref(buttonProps), {
                        onClick: _unref(handleRedirectToBonuses)
                    }), null, 16, [
                        "onClick"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-popup-header__right'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-popup-header__banner'])
                    }, [
                        _createElementVNode("img", {
                            src: require("web/src/modules/referral-program/image/banner.png")
                        }, null, 8, _hoisted_2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            "referral-program-popup-header__arrow",
                            {
                                [_ctx.$style['referral-program-popup-header__arrow']]: true,
                                [_ctx.$style['referral-program-popup-header__arrow-opened']]: !_ctx.successMessage && _ctx.isOpen
                            }
                        ]),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(handleClose) && _unref(handleClose)(...args);
                        })
                    }, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProps))), null, 16)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramPopupHeader'
                ]
            ]);
        };
    }
});
