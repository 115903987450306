import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_4 = [
    "src"
];
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useReferralProgramRegistrationTopBar } from 'web/src/modules/referral-program/components/ReferralProgramRegistrationTopBar/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramRegistrationTopBar',
    props: {
        disableRedirect: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { iconProps, registrationTitle, brandName, handleClick } = useReferralProgramRegistrationTopBar(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program-top-bar'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['referral-program-top-bar__content'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-top-bar__left'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['referral-program-top-bar__title'])
                        }, _toDisplayString(_unref(registrationTitle)), 3),
                        _createVNode(VBrand, {
                            class: _normalizeClass(_ctx.$style['referral-program-top-bar__logo']),
                            alt: _unref(brandName),
                            "is-dark": !!((void 0) ?? void 0) || void 0
                        }, null, 8, [
                            "class",
                            "alt",
                            "is-dark"
                        ]),
                        (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['referral-program-top-bar__close'])
                        }, [
                            _createVNode(VButton, _mergeProps(_unref(iconProps), {
                                class: _ctx.$style['referral-program-top-bar__close-button'],
                                onClick: _unref(handleClick)
                            }), null, 16, [
                                "class",
                                "onClick"
                            ])
                        ], 2))
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['referral-program-top-bar__right'])
                    }, [
                        (_openBlock(), _createElementBlock("img", {
                            key: 3,
                            class: _normalizeClass(_ctx.$style['referral-program-top-bar__image']),
                            src: require("web/src/modules/referral-program/image/banner-registration.png"),
                            height: "120",
                            alt: ""
                        }, null, 10, _hoisted_4))
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramRegistrationTopBar'
                ]
            ]);
        };
    }
});
