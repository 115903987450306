import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import ButtonKind from 'web/src/components/Button/VButton/enums/ButtonKind';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useReferralProgramRegistrationTopBar(props, emit) {
    const router = useRouter();
    const { $translate } = useI18n();
    const iconProps = {
        iconSize: IconSize.SIZE_24,
        kind: ButtonKind.TRANSPARENT,
        iconName: IconName.CROSS
    };
    const brandName = $translate('WEB2_BRAND_NAME_SHORT');
    const registrationTitle = $translate('WEB2_REFERRAL_REGISTRATION_TITLE');
    const handleClick = ()=>{
        if (!props.disableRedirect) router.closeModal();
        emit('click');
    };
    return {
        iconProps,
        registrationTitle,
        brandName,
        handleClick
    };
}
