import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import ReferralProgramPopupHeader from 'web/src/modules/referral-program/components/ReferralProgramPopupHeader/ReferralProgramPopupHeader.vue';
import useReferralProgramPopup from 'web/src/modules/referral-program/views/ReferralProgramPopup/composables/useReferralProgramPopup';
import ReferralProgramPopupReward from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/ReferralProgramPopupReward.vue';
import ReferralProgramPopupListSteps from 'web/src/modules/referral-program/components/ReferralProgramPopupListSteps/ReferralProgramPopupListSteps.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramPopup',
    setup (__props) {
        const { isReferral, toggleOpen, headerProps, bodyProps, blockRef, blockStyle, isOpen, isReferrerRequirementSatisfied, handleClose, isVisible, handleRedirect } = useReferralProgramPopup();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isReferral) && _unref(isVisible) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
                    [_ctx.$style['referral-program-popup']]: true,
                    [_ctx.$style['referral-program-popup--open']]: !_unref(isReferrerRequirementSatisfied) && _unref(isOpen)
                })
            }, [
                _createVNode(ReferralProgramPopupHeader, _mergeProps(_unref(headerProps), {
                    onClick: _unref(toggleOpen),
                    onClose: _unref(handleClose),
                    onRedirect: _unref(handleRedirect)
                }), null, 16, [
                    "onClick",
                    "onClose",
                    "onRedirect"
                ]),
                _unref(isReferrerRequirementSatisfied) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['referral-program-popup__bottom-container']),
                    style: _normalizeStyle(_unref(blockStyle))
                }, [
                    _createElementVNode("div", {
                        ref_key: "blockRef",
                        ref: blockRef,
                        class: _normalizeClass(_ctx.$style['referral-program-popup__body'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['referral-program-popup__body-column'])
                        }, [
                            _unref(bodyProps).completed.length ? (_openBlock(), _createBlock(ReferralProgramPopupListSteps, {
                                key: 0,
                                title: _ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_DONE'),
                                satisfied: "",
                                list: _unref(bodyProps).completed
                            }, null, 8, [
                                "title",
                                "list"
                            ])) : _createCommentVNode("", true),
                            _unref(bodyProps).current.length ? (_openBlock(), _createBlock(ReferralProgramPopupListSteps, {
                                key: 1,
                                title: _ctx.$t('WEB2_REFFERAL_PROGRAM_POPUP_CURRENT'),
                                list: _unref(bodyProps).current,
                                "is-rounded": ""
                            }, null, 8, [
                                "title",
                                "list"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['referral-program-popup__body-column'])
                        }, [
                            _createVNode(ReferralProgramPopupReward, _normalizeProps(_guardReactiveProps(_unref(bodyProps).rewards)), null, 16)
                        ], 2)
                    ], 2)
                ], 6))
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramPopup'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
