import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
export default function useReferralProgramRegistration() {
    const router = useRouter();
    const referralProgramStore = useReferralProgramStore();
    const rewardsData = toRef(referralProgramStore, 'rewards');
    const rewards = computed(()=>rewardsData.value ?? void 0);
    const handleClick = ()=>{
        router.push({
            name: RouteName.REGISTRATION
        });
    };
    const initialize = async ()=>{
        const referralCode = router.getParam('code');
        try {
            await referralProgramStore.setRewards(referralCode || '');
        } catch  {
            router.push({
                name: RouteName.HOME
            });
        }
    };
    initialize();
    return {
        rewards,
        handleClick
    };
}
