import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import ButtonHeight from 'web/src/components/Button/VButton/enums/ButtonHeight';
export default function useReferralProgramPopupHeader(props, emits) {
    const { $translate } = useI18n();
    const iconProps = computed(()=>{
        let params = {
            name: IconName.EXPAND_DOWN
        };
        if (props.isOpen) params.name = IconName.EXPAND_UP;
        if (props.successMessage) params = {
            name: IconName.CROSS,
            size: IconSize.SIZE_16
        };
        return params;
    });
    const buttonProps = computed(()=>({
            kind: ButtonKind.MONOCHROME,
            label: $translate('WEB2_GRAB').value,
            height: ButtonHeight.TINY
        }));
    const handleClickOnContainer = ()=>{
        if (!props.successMessage) emits('click');
    };
    const handleClose = ()=>{
        if (props.successMessage) emits('close');
    };
    const handleRedirectToBonuses = ()=>{
        if (props.successMessage) emits('redirect');
    };
    return {
        iconProps,
        buttonProps,
        handleClickOnContainer,
        handleRedirectToBonuses,
        handleClose
    };
}
