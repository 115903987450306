import { ref, computed, toRef, onMounted, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { CustomerConfig, RefProgramNotificationType } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getBodyProps, getHeaderProps } from 'web/src/modules/referral-program/utils/referralProgramPopup';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { useUserStore } from 'web/src/modules/user/store';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { useI18nStore } from 'web/src/modules/i18n/store';
export default function useReferralProgramPopup() {
    const referralProgramStore = useReferralProgramStore();
    const { $translate } = useI18n();
    const { locale } = useI18nStore();
    const formatMoney = useFormatMoney();
    const { isLoggedIn } = useIsLoggedIn();
    const { setAdditionalPropsUser } = useUserStore();
    const router = useRouter();
    const refFrontNotification = toRef(useFrontNotificationsStore(), 'refFrontNotification');
    const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');
    const isReferralProgramMainBlockEnabled = toRef(useSiteConfigStore(), 'isReferralProgramMainBlockEnabled');
    const programReferral = toRef(referralProgramStore, 'programReferral');
    const isReferralProgramNotificationClicked = toRef(useCustomerDataStore(), 'isReferralProgramNotificationClicked');
    const isOpen = ref(false);
    const isVisible = ref(true);
    const isNotificationClicked = ref(isReferralProgramNotificationClicked.value);
    const blockRef = ref();
    const blockHeight = ref(0);
    // eslint-disable-next-line max-len
    const isAllRequirementsCompleted = computed(()=>!programReferral.value?.referralDetails?.requirements.filter((item)=>false === item.satisfied).length);
    const isReferrerRequirementSatisfied = computed(()=>{
        // eslint-disable-next-line max-len
        const isCorrectStatus = refFrontNotification.value?.find((message)=>message?.payload.type === RefProgramNotificationType.REFERRER_REQUIREMENT_SATISFIED);
        return !!isCorrectStatus && isAllRequirementsCompleted.value && !isReferralProgramNotificationClicked.value;
    });
    const isReferral = computed(()=>// don't show if user has completed all requirements;
        // eslint-disable-next-line implicit-arrow-linebreak
        !!programReferral.value && !isReferralProgramNotificationClicked.value || !!programReferral.value && isReferrerRequirementSatisfied.value && isVisible.value);
    const bodyProps = computed(()=>getBodyProps(programReferral.value, $translate, formatMoney, locale));
    const headerProps = computed(()=>getHeaderProps(isOpen.value, programReferral.value, isAllRequirementsCompleted.value, bodyProps.value.rewards.rewardLabel));
    watchEffect(()=>{
        if (isLoggedIn.value && isProfileReferralProgramEnabled.value) referralProgramStore.setProgramReferral();
        if (isLoggedIn.value && !isProfileReferralProgramEnabled.value) referralProgramStore.clearProgramReferral();
        if (!isLoggedIn.value) referralProgramStore.clearProgramReferral();
        if (refFrontNotification.value && isLoggedIn.value && isProfileReferralProgramEnabled.value) referralProgramStore.setProgramReferral();
    });
    async function hideNotification() {
        await setAdditionalPropsUser({
            value: {
                value: true
            },
            config: CustomerConfig.IS_REFERRAL_PROGRAM_NOTIFICATION_CLICKED
        });
        isNotificationClicked.value = true;
        isVisible.value = false;
    }
    function calculateBlockHeight() {
        blockHeight.value = blockRef.value?.clientHeight || 0;
    }
    onMounted(()=>{
        if (isReferralProgramMainBlockEnabled.value) calculateBlockHeight();
        else isVisible.value = false;
    });
    const toggleOpen = ()=>{
        blockHeight.value = blockRef.value?.clientHeight || 0;
        isOpen.value = !isOpen.value;
    };
    const blockStyle = computed(()=>({
            height: isOpen.value ? `${blockHeight.value}px` : 0
        }));
    const handleClose = ()=>{
        hideNotification();
    };
    const handleRedirect = ()=>{
        hideNotification().then(()=>{
            router.push({
                name: RouteName.BONUSES
            });
        });
    };
    return {
        isOpen,
        isReferral,
        toggleOpen,
        headerProps,
        bodyProps,
        blockRef,
        blockHeight,
        blockStyle,
        isReferrerRequirementSatisfied,
        handleClose,
        isVisible,
        handleRedirect
    };
}
