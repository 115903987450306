import getCompletedProgramRequirements from 'web/src/modules/referral-program/utils/referralProgramPopup/getCompletedProgramRequirements';
import getRequirementsTitle from 'web/src/modules/referral-program/utils/referralProgramPopup/getRequirementsTitle';
import getRequirementsRoute from 'web/src/modules/referral-program/utils/referralProgramPopup/getRequirementsRoute';
import getRewardTitle from 'web/src/modules/referral-program/utils/referralProgramPopup/getRewardTitle';
import getCurrentProgramRequirements from 'web/src/modules/referral-program/utils/referralProgramPopup/getCurrentProgramRequirements';
import getPlayerReward from 'web/src/modules/referral-program/utils/referralProgramPopup/getPlayerReward';
export default function getBodyProps(programReferral, $t, formatMoney, locale) {
    const completedProgramRequirements = getCompletedProgramRequirements(programReferral);
    const currentProgramRequirements = getCurrentProgramRequirements(programReferral);
    const playerReward = getPlayerReward(programReferral?.rewards ?? null, $t, formatMoney, locale);
    const completed = completedProgramRequirements.map((requirement)=>({
            label: getRequirementsTitle(requirement, $t, formatMoney)
        }));
    const current = currentProgramRequirements.map((requirement)=>({
            label: getRequirementsTitle(requirement, $t, formatMoney),
            to: getRequirementsRoute(requirement.type)
        }));
    const referralReward = programReferral?.referralDetails?.referralReward;
    return {
        completed,
        current,
        rewards: {
            referralReward: !referralReward,
            table: playerReward,
            rewardLabel: referralReward ? getRewardTitle(referralReward, $t, locale) : '',
            showTitle: true
        }
    };
}
