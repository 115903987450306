import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import RouteName from '@leon-hub/routing-config-names';
import { useReferralProgramRegistration } from 'web/src/modules/referral-program/views/ReferralProgramRegistration/composables';
import ReferralProgramRegistrationBody from // eslint-disable-next-line max-len
'web/src/modules/referral-program/components/ReferralProgramRegistrationBody/ReferralProgramRegistrationBody.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramRegistration',
    setup (__props) {
        const { rewards, handleClick } = useReferralProgramRegistration();
        return (_ctx, _cache)=>{
            _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['referral-program-reg'])
            }, [
                _unref(rewards) ? (_openBlock(), _createBlock(ReferralProgramRegistrationBody, _mergeProps({
                    key: 0
                }, _unref(rewards), {
                    onClick: _unref(handleClick)
                }), null, 16, [
                    "onClick"
                ])) : _createCommentVNode("", true),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ReferralProgramRegistration'
                ]
            ]);
        };
    }
});
