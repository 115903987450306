import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'ReferralProgramRewardItem',
    props: {
        items: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index)=>_withDirectives((_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(_ctx.$style['referral-program-reward-item'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['referral-program-reward-item--name'])
                    }, _toDisplayString(item), 3),
                    index < _ctx.items.length - 1 ? _withDirectives((_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['referral-program-reward-item--separator'])
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('REFERRAL_PROGRAM_INFO_LABEL_OR')), 1)
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'referral-program-reward-item-separator'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ], 2)), [
                    [
                        _directive_auto_id,
                        'ReferralProgramRewardItem'
                    ],
                    [
                        _directive_data_test,
                        {
                            el: 'referral-program-reward-item'
                        }
                    ]
                ])), 128);
        };
    }
});
